.App {
  text-align: center;
}

/*---------------------------------------------
    Header / layouts
--------------------------------------------- */
.header-part {
  width: 100%;
  height: 80px;
  position: fixed;
  z-index: 99;
  background-color: rgba(255, 255, 255);
  top: 0;
}
.head-icon {
  position: fixed;
  width: 180px;
  height: 50px;
  top: 18px;
  left: 20px;
  background-image: url("./images/head_LOGO.png");
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
}
.head-ul {
  display: flex;
}
.head-ul li {
  list-style: none;
}
.head-txt {
  margin-top: 0;
  background-color: #fff;
}
.head-txt ul {
  text-align: left;
  margin-left: 180px;
}
.head-txt-cnt {
  width: 700px;
  font-weight: bold;
  color: #303030;
  font-size: 18px;
  letter-spacing: 2px;
}
.head-txt-sm-none {
  line-height: 28px;
  color: #009714;
}
.header-block-fix {
  position: fixed;
  top: 0;
  right: 10px;
}
.head-tel-img {
  background-image: url("./images/Head-tell.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 300px;
  height: 70px;
  margin-right: 10px;
  position: fixed;
  top: 5px;
  right: 310px;
}
.head-contact-img {
  background-image: url("./images/Head-form.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 300px;
  height: 70px;
  position: fixed;
  top: 5px;
  right: 10px;
}
.sm-mt-1 {
  display: none;
}
.header-subpart {
  width: 100%;
  height: 40px;
  background-color: #ffa908;
  margin-top: -16px;
}
.head-ul a {
  text-decoration: none;
}
.header-sub-menu {
  width: 900px;
  height: 40px;
  line-height: 40px;
  font-weight: bold;
  margin: 0 auto;
  font-size: 20px;
  text-align: left;
}
.header-sub-menu li {
  list-style: none;
}
.header-sub-menu-li {
  color: #fff;
  margin-left: 30px;
  margin-right: 30px;
}
.header-sub-menu a {
  text-decoration: none;
}
.head-menu-ul {
  display: flex;
}
.head-menu-ul a {
  text-decoration: none;
}
.header-sub-menu {
  display: flex;
}
.head-menu-ul li {
  list-style: none;
  margin-left: 8px;
  cursor: pointer;
  transition: 0.5s;
}
.header-sub-menu-li a {
  color: #fff;
}
.head-menu-ul li:hover {
  opacity: 0.4;
}
.tel-a a {
  text-decoration: none;
}
.menu-bar {
  display: none;
}
.sm-shown {
  display: none;
}
@media screen and (max-width: 960px) {
  .head-menu-mx {
    display: block;
  }
  .head-ul {
    height: 60px;
    padding-left: 30px;
  }
  .menu-bar {
    display: none;
  }
  .head-txt-cnt {
    font-size: 15px;
    width: 600px;
    line-height: 24px;
    letter-spacing: 2px;
  }
  .head-menu-ul li {
    margin-left: 20px;
  }
  .menu-bar {
    display: block;
  }
  .header-block-fix {
    position: fixed;
    top: 20px;
    right: 146px;
  }
  .head-sm-hide {
    display: none;
  }
  .header-sub-menu {
    margin-top: -12px;
  }
  .header-sub-menu {
    width: 780px;
    margin: 0 auto;
    font-size: 20px;
    padding-left: 0;
  }
  .header-sub-menu-li {
    margin-left: 20px;
    margin-right: 20px;
  }
  .head-tel {
    background-color: #fff;
    width: 60px;
    height: 70px;
    position: fixed;
    top: 0;
    right: 130px;
  }
  .head-tel-img {
    background-image: url("./images/tel-icon.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 60px;
    height: 60px;
    position: fixed;
    top: 12px;
    right: 130px;
  }
  .head-contact {
    background-color: #fff;
    width: 60px;
    height: 70px;
    position: fixed;
    top: 0;
    right: 70px;
  }
  .head-contact-img {
    background-image: url("./images/mail-icon.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 60px;
    height: 60px;
    position: fixed;
    top: 12px;
    right: 57px;
  }
}
@media (min-width: 561px) {
  a[href^="tel:"] {
    pointer-events: none;
    cursor: default;
  }
}
@media (max-width: 560px) {
  .head-txt ul {
    margin-left: 166px;
    margin-top: 10px;
  }
  .menu-bar {
    display: block;
  }
}
@media screen and (max-width: 430px) {
  .header-part {
    height: 60px;
  }

  .head-icon {
    background-image: url("./images/icon-2x.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 40px;
    height: 40px;
    top: 15px;
    left: 7px;
  }
  .head-txt-cnt {
    font-size: 13px;
    width: 270px;
    line-height: 19px;
    letter-spacing: 0;
    margin-top: 4px;
  }
  .head-txt-sm-none {
    display: none;
  }
  .header-subpart {
    display: none;
  }
  .sm-shown {
    display: block;
  }
  .head-menu-mx {
    display: none;
  }
  .head-txt {
    margin-top: 2px;
    margin-left: -145px;
  }
  .head-tel {
    background-color: #fff;
    width: 50px;
    height: 70px;
    position: fixed;
    top: 0;
    right: 100px;
  }
  .head-tel-img {
    background-image: url("./images/tel-icon.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 40px;
    height: 40px;
    position: fixed;
    top: 15px;
    right: 95px;
  }
  .head-contact {
    background-color: #fff;
    width: 50px;
    height: 70px;
    position: fixed;
    top: 0;
    right: 50px;
  }
  .head-contact-img {
    background-image: url("./images/mail-icon.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 40px;
    height: 40px;
    position: fixed;
    top: 15px;
    right: 57px;
  }
  .sm-mt-1 {
    display: block;
    margin-top: -20px;
  }
  .head-menu {
    top: 0;
  }
  .menu-bar {
    width: 40px;
    height: 45px;
  }
  .bm-menu {
    margin-top: -32px;
    z-index: 999;
  }
  .bm-morph-shape {
    margin-top: -32px;
    margin-right: -3px;
  }
}

/*---------------------------------------------
    FORM / Page
--------------------------------------------- */

.porte-logo {
  background-image: url("./images/PORTE_LOGO.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 200px;
  height: 100px;
  margin: 40px auto 0;
}

.form-wrapper {
  width: 100%;
  height: 420px;
  background-color: rgb(255, 245, 216);
  margin-bottom: 40px;
}
.form-wrapper-title {
  font-family: "futura";
  font-weight: bold;
  padding-top: 80px;
  font-size: 40px;
  letter-spacing: 2px;
  color: #313131;
}
.under-line {
  width: 670px;
  border-bottom: 6px solid #fbd51d;
  margin: -4px auto 0;
}
.contact-part {
  width: 600px;
  margin: 0 auto;
  text-align: left;
}
.alert-success {
  color: rgb(10, 131, 217);
  font-weight: bold;
  font-size: 14px;
  background-color: #dcf0fd;
  padding: 10px;
  width: 200px;
  margin: 0 auto;
}
.home-btn {
  color: #fff;
  font-weight: bold;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #fbd51d;
  position: fixed;
  z-index: 99;
  left: 20px;
  bottom: 20px;
  text-decoration: none;
  line-height: 80px;
}
.home-btn:hover {
  background-color: #fea30b;
}
.thank-txt {
  background-image: url("./images/thanks-txt.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 700px;
  height: 50px;
  margin: 0 auto;
  padding-top: 500px;
}
.poru-illu {
  background-image: url("./images/poru-illus.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 300px;
  height: 200px;
  margin: -170px auto 0;
}
.thanks-bg {
  background-color: #ffecbd;
  height: 780px;
}
.top-link {
  color: #fff;
  width: 200px;
  height: 50px;
  margin: 30px auto;
  background-color: #f87128;
  line-height: 50px;
  font-weight: bold;
  font-size: 24px;
}
.top-link a {
  text-decoration: none;
  color: #fff;
}
.estimate-part {
  z-index: 0;
}
.illus-porte {
  background-image: url("./images/ぽるるんimg1のコピー.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 160px;
  height: 160px;
  margin: 0 auto 40px;
}
.css-6hp17o-MuiList-root-MuiMenu-list {
  height: 300px;
  overflow-y: scroll;
}
#overlay {
  /*　画面全体を覆う設定　*/
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);

  /*　画面の中央に要素を表示させる設定　*/
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 0;
}
#content {
  z-index: 9;
  width: 300px;
  height: 300px;
  background: #fff;
  opacity: 1;
  position: absolute;
}
.modal-cnt {
  background-image: url("./images/POP_img.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 300px;
  height: 300px;
  margin-top: -38px;
}
.modal-closebtn {
  width: 300px;
  height: 40px;
  line-height: 30px;
  font-weight: bold;
  border: none;
  background: #fff;
  letter-spacing: 2px;
  cursor: pointer;
}
.modal-closeicon {
  position: relative;
  top: 0;
  padding-right: 10px;
  width: 30px;
  height: 38px;
  background-color: #ffffffc3;
  line-height: 38px;
  text-align: center;
  font-family: "Futura";
  font-weight: bold;
  padding-left: 10px;
  font-size: 38px;
  color: #3c3c3c;
  cursor: pointer;
}
.tel-info {
  font-size: 20px;
  margin-top: 60px;
}
.form-telBtn {
  background-image: url("./images/TELnew_btn.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 450px;
  height: 150px;
  margin: 0 auto 10px;
  cursor: pointer;
}
.form-wra-txt {
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 20px;
}
@media screen and (max-width: 960px) {
  .mitumori-part {
    width: 100%;
  }
}
@media screen and (max-width: 430px) {
  .form-wrapper {
    width: 100%;
    height: 230px;
    background-color: rgb(255, 245, 216);
    margin-bottom: 10px;
    margin-top: -40px;
  }
  .form-wrapper-title {
    font-family: "futura";
    font-weight: bold;
    padding-top: 20px;
    font-size: 24px;
    letter-spacing: 2px;
  }
  .form-wra-txt {
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 20px;
  }
  .tel-info {
    font-size: 16px;
    margin-top: 20px;
  }
  .form-telBtn {
    width: 320px;
    height: 100px;
    margin: -2px auto 10px;
  }
  .under-line {
    width: 90%;
    margin: -2px auto 0;
  }
  .contact-part {
    width: 90%;
    margin: 0 auto;
    text-align: left;
  }
  .form-label {
    padding-bottom: 12px;
  }
  .input-name {
    width: 85%;
    height: 30px;
    border: none;
    border-bottom: 3px solid rgb(231, 103, 5);
    padding-left: 8px;
    font-size: 14px;
    margin: 0 auto;
  }
  .thank-txt {
    background-position: center;
    width: 330px;
    height: 50px;
    margin: 0 auto;
    padding-top: 300px;
  }
  .poru-illu {
    width: 220px;
    height: 220px;
    margin: -100px auto 0;
  }
  .top-link {
    color: #fff;
    width: 160px;
    height: 30px;
    margin: 10px auto;
    background-color: #f87128;
    line-height: 30px;
    font-weight: bold;
    font-size: 16px;
  }
  .home-btn {
    width: 60px;
    height: 60px;
    font-size: 12px;
    line-height: 60px;
  }
  .illus-porte {
    background-image: url("./images/ぽるるんimg1のコピー.png");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 100px;
    height: 100px;
    margin: 0 auto;
  }
}
.copyrights {
  color: #a2a2a2;
  font-size: 12px;
}
/* -------------------------------
    FROM FormBlock
-------------------------------- */
.formblock-part {
  background-color: #ffffff;
  margin-top: -16px;
  position: relative;
}
.estimate-part {
  width: 60%;
  margin: 0 auto;
}
.form-title-2 {
  color: #313131;
  font-weight: bold;
  font-size: 34px;
}
.formblock-date {
  color: rgb(51, 51, 51);
  text-align: left;
}
.form-send-btn {
  width: 260px;
  height: 60px;
  border: 1px solid #fe6a00;
  color: #fff;
  background-color: #fe6a00;
  font-size: 20px;
  letter-spacing: 2px;
  cursor: pointer;
  font-weight: bold;
}
.form-send-btn:hover {
  border: 1px solid #fe6a00;
  color: #fe6a00;
  background-color: #fff;
}

.sub-title-7 {
  position: absolute;
  font-family: "游ゴシック体", "Yu Gothic", YuGothic, "ヒラギノ角ゴ Pro",
    "Hiragino Kaku Gothic Pro";
  font-size: 100px;
  color: #4c4c4c;
  letter-spacing: 14px;
  font-weight: bold;
  top: -75px;
  right: 40px;
}
.input-sm {
  width: 200px;
}
@media screen and (max-width: 430px) {
  .formblock-part {
    margin-top: 10px;
  }
  .estimate-part {
    width: 90%;
  }
  .sub-title-7 {
    font-family: "游ゴシック体", "Yu Gothic", YuGothic, "ヒラギノ角ゴ Pro",
      "Hiragino Kaku Gothic Pro";
    font-size: 40px;
    letter-spacing: 5px;
    top: -33px;
    right: 20px;
  }
  .form-title-2 {
    font-size: 22px;
  }
  .form-send-btn {
    width: 200px;
    height: 40px;
    font-size: 16px;
    letter-spacing: 2px;
  }
}

/*---------------------------------------------
    Menu / layouts 
--------------------------------------------- */
/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 35px;
  height: 28px;
  right: 12px;
  top: 20px;
  display: none;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #ff8f0f;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #ff8f0f;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #737373;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 50%;
}

/* General sidebar styles */
.bm-menu {
  background: #fea517;
  padding: 40px 0;
  font-size: 24px;
  height: 50%;
  width: 100%;
  text-align: left;
  line-height: 60px;
  z-index: 999;
  font-weight: bold;
  color: rgb(243, 243, 243);
}
.bm-menu a {
  text-decoration: none;
  color: rgb(243, 243, 243);
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #fea517;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
  text-decoration: none;
  outline: none;
  border-bottom: 1px solid #fff;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}
@media screen and (max-width: 560px) {
  .bm-burger-button {
    display: block;
  }
}
@media screen and (max-width: 430px) {
  .bm-burger-button {
    display: block;
  }
}
/*---------------------------------------------
    Menu / layouts  ---END---
--------------------------------------------- */
